import { useCallback, useEffect, useState } from 'react';

import useMissingSubscriptionModal from 'components/views/missing-subscription/hooks/useMissingSubscriptionModal';
import { getSubscriptionTypeNumericValue } from 'utils/subscription/getSubscriptionTypeNumericValue';
import { useGetTeams } from 'services/teamManagement/useGetTeams';
import { AppFeature } from 'types/AppFeatureset';
import { SubscriptionType } from 'types/TeamManagement/subscription';

import { useIsLoggedIn } from '../../store/AppProvider';
import { getSubscriptionFeatureset } from './getSubscriptionFeatureset';

export const useCheckSubscription = ({
  minRequiredSubscriptionType,
  shouldShowModal = false,
  customAllowance,
  feature,
}: {
  minRequiredSubscriptionType: SubscriptionType;
  shouldShowModal?: boolean;
  customAllowance?: {
    minRequiredSubscriptionType: SubscriptionType;
    allowanceKey: 'withTwitterExplorer';
  };
  feature?: AppFeature;
}) => {
  const isLoggedIn = useIsLoggedIn();
  const { selectedTeam, isLoading } = useGetTeams();
  const [wasModalOpened, setWasModalOpened] = useState(false);

  const missingSubscriptionModal = useMissingSubscriptionModal(
    minRequiredSubscriptionType,
  );

  const minRequiredSubscriptionTypeNumericValue =
    getSubscriptionTypeNumericValue(minRequiredSubscriptionType);
  const selectedTeamSubscriptionTypeNumericValue =
    getSubscriptionTypeNumericValue(selectedTeam?.subscriptionType);

  // Helper function to check feature access for the current subscription type
  const hasFeatureAccess = useCallback(() => {
    if (!feature || !selectedTeam?.subscriptionType) return true;

    const teamFeatures = getSubscriptionFeatureset(
      selectedTeam.subscriptionType,
    );
    return teamFeatures.includes(feature);
  }, [feature, selectedTeam?.subscriptionType]);

  const isSubscriptionValid = useCallback(() => {
    if (
      !selectedTeam?.isDemo &&
      minRequiredSubscriptionType !== SubscriptionType.None
    ) {
      const isSubscriptionCheckValid =
        Boolean(selectedTeam?.isSubscriptionActive) &&
        selectedTeamSubscriptionTypeNumericValue >=
          minRequiredSubscriptionTypeNumericValue &&
        hasFeatureAccess(); // Check for feature access here

      // Bypass subscription check if custom allowance key is provided
      if (!isSubscriptionCheckValid && customAllowance) {
        const customAllowanceSubscriptionTypeNumericValue =
          getSubscriptionTypeNumericValue(
            customAllowance.minRequiredSubscriptionType,
          );

        return (
          Boolean(selectedTeam?.isSubscriptionActive) &&
          selectedTeamSubscriptionTypeNumericValue >=
            customAllowanceSubscriptionTypeNumericValue &&
          Boolean(selectedTeam?.teamSettings?.[customAllowance.allowanceKey])
        );
      }

      return isSubscriptionCheckValid;
    }

    return true;
  }, [
    selectedTeam?.isDemo,
    selectedTeam?.isSubscriptionActive,
    selectedTeam?.teamSettings,
    minRequiredSubscriptionType,
    selectedTeamSubscriptionTypeNumericValue,
    minRequiredSubscriptionTypeNumericValue,
    customAllowance,
    hasFeatureAccess, // Include feature access check
  ]);

  const hasSubscriptionExpired =
    !isLoading &&
    !selectedTeam?.isSubscriptionActive &&
    Boolean(selectedTeam?.subscriptionType);

  const isSubscriptionPlanInsufficient = () => {
    if (customAllowance) {
      const customAllowanceSubscriptionTypeNumericValue =
        getSubscriptionTypeNumericValue(
          customAllowance.minRequiredSubscriptionType,
        );

      return (
        Boolean(selectedTeam?.isSubscriptionActive) &&
        selectedTeamSubscriptionTypeNumericValue <
          customAllowanceSubscriptionTypeNumericValue
      );
    }
    return (
      Boolean(selectedTeam?.isSubscriptionActive) &&
      selectedTeamSubscriptionTypeNumericValue <
        minRequiredSubscriptionTypeNumericValue
    );
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    if (isLoading) return;

    if (!isSubscriptionValid() && shouldShowModal && !wasModalOpened) {
      // delay to avoid glitches with old team in store during re-renders
      const delayTimeout = setTimeout(() => {
        missingSubscriptionModal.open();
        setWasModalOpened(true);
      }, 500);

      return () => clearTimeout(delayTimeout);
    }
  }, [
    isSubscriptionValid,
    missingSubscriptionModal,
    shouldShowModal,
    wasModalOpened,
    isLoading,
    isLoggedIn,
  ]);

  return {
    isSubscriptionValid: isSubscriptionValid(),
    hasSubscriptionExpired,
    isSubscriptionPlanInsufficient: isSubscriptionPlanInsufficient(),
    isLoading,
  };
};
