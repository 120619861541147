import { useEffect } from 'react';
import localFont from 'next/font/local';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { withPasswordProtect } from 'next-password-protect';
import CssBaseline from '@mui/material/CssBaseline';
import { Toaster } from 'sonner';
import { cn } from 'src/utils';
import CookieDrawer from 'components/CookieDrawer';
import PageShiftProgressBar from 'components/PageShiftProgressBar';
import WithBackdrop from 'components/WithBackdrop';
import isMaintenance from 'utils/isMaintenance';
import isProductionEnv from 'utils/isProductionEnv';
import { ColorModeContextProvider } from 'context/ColorModeContext';
import { CookieAnalytics } from 'lib/cookieAnalytics';
import { GtagScript } from 'lib/gtag';
import Posthog from 'lib/posthog';
import TwitterPixel from 'lib/twitter-pixel';
import WithSnackbar from '../components/WithSnackbar';
import { AppWrapper } from '../store/AppProvider';
import '../theme/globals.css';
declare global {
  interface Window {
    cookie3: {
      trackEvent: (params: {
        category: string;
        action: string;
        name?: string;
        value?: number;
      }) => void;
    };
  }
}
const helvetica = localFont({
  src: '../fonts/HelveticaNowVar.ttf',
  variable: '--font-helvetica'
});
const MyApp = ({
  Component,
  pageProps: {
    session,
    ...pageProps
  }
}: {
  Component: any;
  pageProps: {
    session: any;
  };
}) => {
  const router = useRouter();
  useEffect(() => {
    if (router.pathname !== '/maintenance') isProductionEnv && isMaintenance && router.push('/maintenance');
  }, [router]);
  return <>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700&display=swap" rel="stylesheet" />
      </Head>
      <TwitterPixel />
      <div className={cn(helvetica.variable, 'font-sans text-ds-text-primary antialiased')}>
        <Posthog>
          <ColorModeContextProvider>
            <PageShiftProgressBar />
            <CssBaseline />
            <WithSnackbar>
              <AppWrapper>
                <WithBackdrop>
                  <Component {...pageProps} />
                  <CookieDrawer />
                </WithBackdrop>
              </AppWrapper>
            </WithSnackbar>
          </ColorModeContextProvider>
        </Posthog>
      </div>
      {isProductionEnv && <GtagScript />}
      {isProductionEnv && <CookieAnalytics />}
    </>;
};
export default isProductionEnv ? MyApp : withPasswordProtect(MyApp, {
  loginApiUrl: '/api/appLogin',
  checkApiUrl: '/api/appPasswordCheck'
});