import { useState } from 'react';

import { Toaster } from 'components/ui/sonner';
import { SnackbarVariant } from 'utils/constants';
import SnackbarContext, { SnackbarDataProps } from 'context/SnackbarContext';

import Snackbar from './Snackbar';

interface Props {
  children: React.ReactNode;
}

export default function WithSnackbar({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState<SnackbarDataProps>({
    message: '',
    variant: SnackbarVariant.success,
  });
  const [duration, setDuration] = useState(5000);

  const { message, variant, subtitle } = snackbarData;
  return (
    <>
      {' '}
      <Toaster />
      <SnackbarContext.Provider
        value={{ setIsOpen, setSnackbarData, setDuration }}
      >
        {children}
        <Snackbar
          handleClose={() => setIsOpen(false)}
          {...{ isOpen, message, variant, duration, subtitle }}
        />
      </SnackbarContext.Provider>
    </>
  );
}
